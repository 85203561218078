<template>
<div>
  <div class="main">
    <div class="title">
        <span>會員登入</span>
    </div>

    <div class="title-msg">
        <span>請輸入登入帳號和密碼</span>
    </div>

    <form class="login-form" >
        <div class="input-content">
            <div>
                <input type="text" v-model="account" placeholder="使用者名稱" v-on:keyup.enter="memlogin()"/>
            </div>
            <div style="margin-top: 16px">
                <input type="password" v-model="password" placeholder="登入密碼" v-on:keyup.enter="memlogin()"/>
            </div>
        </div>

        <div style="text-align: center">
            <button type="button" class="enter-btn" @click="memlogin()">登入</button>
        </div>
        <!-- <div class="foor">
            <div class="left"><span>忘記密碼 ?</span></div>

            <div class="right"><span>註冊賬戶</span></div>
        </div> -->
    </form>
    </div>
</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  data () {
    return {
      account: '',
      password: ''
    }
  },
  methods: {
    ...mapMutations([
      'memberLogin'
    ]),
    ...mapActions([
      'loginFunction'
    ]),
    memlogin () {
      if (this.account !== 'admin' && this.password !== 'admin') {
        const member = {
          account: this.account,
          password: this.password
        }
        this.loginFunction(member)
      }
    }
  },
  computed: {
    ...mapState([
      'login'
    ])
  },
  mounted () {
    // this.postAuthorizeToken()
  }
}
</script>

<style lang="scss" scoped>
    @import '../assets/scss/Login.scss'
</style>
